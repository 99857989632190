import { useEffect } from "react";

function RedirectToUserManagement() {
  useEffect(() => {
    window.location.href = window._env_.REACT_APP_USER_MANAGEMENT_URL;
  }, []);

  return null;
}

export default RedirectToUserManagement;

