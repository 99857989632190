import { ReactComponent as LogoutIcon } from "../../assets/icons/bagstage_logout.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/bagstage_user.svg";
import StandardButton from "../UI/buttons/StandardButton";
import StandardLink from "../UI/buttons/StandardLink";
import homePageStyles from "../../styles/homePage.module.css";
import { useAppSelector } from "../../store/hooks";
import { useTranslation } from "react-i18next";
import StandardLinkButton from "../UI/buttons/StandardLinkButton";
import URLService from "../../service/urlService";

export default function Footer({ isLoggedIn }: { isLoggedIn: boolean }) {
  const { t } = useTranslation();

  const { kcObject } = useAppSelector((state) => state.authenticationSlice);

  const toggleLogin = () => {
    if (isLoggedIn) {
      return kcObject?.logout();
    } else {
      return kcObject?.login();
    }
  };

  return (
    <footer className={homePageStyles.footerSection}>
      <StandardButton
        onClick={toggleLogin}
        classes={homePageStyles.footerButtonText}
        variant="tertiary"
        icon={<LogoutIcon />}
      >
        {isLoggedIn ? t("log-out") : t("log-in")}
      </StandardButton>

      <div className="flex gap-spacing-s">
        <p className={homePageStyles.customLinkGreyText}>Bagstage v 1.9</p>
        <StandardLink
          to="/"
          classes={`${homePageStyles.customLinkGreyText} underline underline-offset-2`}
        >
          {t("see-release-notes")}
        </StandardLink>
      </div>

      {/* TODO: also check for role */}
      {isLoggedIn ? (
        <StandardLinkButton
          to={URLService.generateRedirectUrl(
            window._env_.REACT_APP_USER_MANAGEMENT_URL
          )}
          classes={homePageStyles.userManagementButton}
          icon={<UserIcon />}
          variant="tertiary"
        >
          {t("user-management")}
        </StandardLinkButton>
      ) : (
        <div />
      )}
    </footer>
  );
}
