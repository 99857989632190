import { useEffect } from "react";

function RedirectToArrivalAnalyst() {
  useEffect(() => {
    window.location.href = window._env_.REACT_APP_ARRIVAL_ANALYST_URL;
  }, []);

  return null;
}

export default RedirectToArrivalAnalyst;

